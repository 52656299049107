<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <button @click="togo">点击去看看</button>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  created () {
    // console.log( Adjust.getAttribution());


  setTimeout(() => {
    Adjust.trackEvent({
      eventToken: "jf9n3i",
    }).then(res => {
      // console.log(res, '11');
      if (res.status == 'success') {
        // console.log( Adjust.getAttribution(), res.adid)
        alert(res.adid)
        location.href = 'https://47.251.113.155'
      }
    })
  },3000)
  },
  methods: {
    togo () {
      location.href = 'https://47.251.113.155/'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
